import React from "react";

// CSS imports

export default class MainHeadline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
        <div className="home-inner navbar-padding" style={{ height: '400px' }} >
        <div className="container">
          <div className="row">
            <div className="col col-lg-9 d-none d-md-block">
              <span className="h1 display-4 text-white d-block">Proven Apps,&nbsp;</span>
              <span className="h1 display-4 text-white d-block second-cta-line">Great Websites,</span>
              <span className="h1 display-4 text-white d-block third-cta-line">Cutting-Edge Solutions</span>
            </div>
            <div className="col col-lg-3 d-none d-md-block"></div>
          </div>
        </div>
      </div>  
    );
  }
}
