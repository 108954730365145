import React from "react";

// CSS imports
import "../../assets/sass/card_section.scss";

export default ({ id, children }) => {
  return (
    <section id={id} className="card-section bg-light text-muted py-5">
      <div className="container">
        <div className="row">{children}</div>
      </div>
    </section>
  );
};
