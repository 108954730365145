import React from "react";

// CSS Imports

export default class SectionHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
        <section id={this.props.id} className={this.props.heading_type}>
            <div className="container">
                <div className="row">
                    <div className="col text-center text-white">
                        <div className="py-3">
                            <h1 className="display-4 text-uppercase">{this.props.title}</h1>
                            <p className="lead">{this.props.details}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
  }
}
