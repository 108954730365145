import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Parallax, Background } from 'react-parallax';

// Component imports
import Layout from "../components/common/layout";
import SectionHeader from "../components/index/SectionHeader";
import CardSection from "../components/common/card_section";
import TSSCard from "../components/common/TSSCard";
import MainHeadline from "../components/index/MainHeadline";
import SEO from "../components/common/seo";
import Contact from "../components/common/Contact";

// Image imports
import grand_tetons1 from "../assets/img/index/grand_tetons1_small2.jpg";
import AlexJones from "../assets/img/index/alex-jones-8205-unsplash.jpg";
import TirzaVanDijk from "../assets/img/index/tirza-van-dijk-72373-unsplash.jpg";
import EmmaSimpson from "../assets/img/index/emma-simpson-153970-unsplash.jpg";
import RohitTandon from "../assets/img/index/rohit-tandon-105772-unsplash.jpg";
import HalGatewood from "../assets/img/index/hal-gatewood-613602-unsplash.jpg";
import MarkusSpiske from "../assets/img/index/markus-spiske-221494-unsplash.jpg";

// CSS imports
import "../assets/sass/index.scss";

export default ({ data }) => {
  return (
    <Layout>
      <SEO title="TalonStrike Software Home" keywords={[`talonstrike`, `talonstrikesoftware`]} />
      <Parallax strength={200}>
        <Background className="custom-bg">
          <img src={grand_tetons1} alt="test" />
        </Background>
        <MainHeadline />
      </Parallax>
      <SectionHeader
        id="solutions"
        heading_type="solutions-section-head"
        title="Solutions"
        details="Custom solutions to meet specific needs"
      />
      <CardSection>
        <TSSCard
          title="Pain Logger"
          image={AlexJones}
          description="Personal pain level logging app for you understand your pain"
          item1="Track your pain levels"
          item2="Graph your pain over time"
          item3="Log multiple pain areas"
          toPage="pain_logger/pain_logger"
          buttonName="Details"
        />
        <TSSCard
          title="Competition Manager"
          image={TirzaVanDijk}
          description="Automated registration and management for the annual NCNYI competition"
          item1="Competitor registration"
          item2="Evaluation and score sheets"
          item3="Detailed results reporting"
          toPage="comp_manager/comp_manager"
          buttonName="Details"
        />
        <TSSCard
          title="My-Habits"
          image={EmmaSimpson}
          description="Habit tracking app for standalone use or with your bullet journal"
          item1="Track daily habits and goals"
          item2="See monthly progress"
          item3="Customizable schedules"
          toPage="my_habits/my_habits"
          buttonName="Details"
        />
      </CardSection>
      <SectionHeader
        id="services"
        heading_type="services-section-head"
        title="Services"
        details="Complete one-stop shop providing expertise for all application tiers."
      />
      <CardSection>
        <TSSCard
          title="iOS App Development"
          image={RohitTandon}
          description="I build custom apps to solve my customer's specific needs"
          item1="Use latest technologies"
          item2="Maintainable code"
          item3="5+ years of experience"
          toPage="ios_development"
          buttonName="Details"
        />
        <TSSCard
          title="Web Client Solutions"
          image={HalGatewood}
          description="I build front end web applications"
          item1="Primarily focused on React based solutions"
          item2="Fully experienced in latest JavaScript tech"
          item3="10+ year of experience"
          toPage="web_solutions"
          buttonName="Details"
        />
        <TSSCard
          title="Server Solutions"
          image={MarkusSpiske}
          description="I build server solutions to support my client applications."
          item1="Specializing in Ruby on Rails"
          item2="All solutions run in container environments on self managed servers"
          item3="20+ years of experience"
          toPage="server_solutions"
          buttonName="Details"
        />
      </CardSection>
      <Contact />
    </Layout>
  );
};
