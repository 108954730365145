import React from "react";
import {
    Card,
    CardImg,
    CardBody,
    CardTitle,
    CardSubtitle,
} from "reactstrap";
import { Link } from "gatsby";

export default class TSSCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    return (
        <div className="col col-sm-12 col-lg-4 text-dark">
            <Card className="card my-1">
                <CardImg top width="100%" className="d-block" src={this.props.image} />
                <CardBody>
                    <CardTitle className="h4">{this.props.title}</CardTitle>
                    <CardSubtitle className="h6 text-muted mb-2">{this.props.description}</CardSubtitle>
                    <div className="d-flex d-row">
                        <div className="p-2 align-self-start"><em className="fa fa-check"></em></div>
                        <div className="p-2 align-self-end"><span className="lead">{this.props.item1}</span></div>
                    </div>
                    <div className="d-flex d-row">
                        <div className="p-2 align-self-start"><em className="fa fa-check"></em></div>
                        <div className="p-2 align-self-end"><span className="lead">{this.props.item2}</span></div>
                    </div>
                    <div className="d-flex d-row">
                        <div className="p-2 align-self-start"><em className="fa fa-check"></em></div>
                    <div className="p-2 align-self-end"><span className="lead">{this.props.item3}</span></div>
                </div>
                <div className="mx-auto text-center">
                    <Link to={this.props.toPage} className="btn btn-primary text-uppercase">{this.props.buttonName}</Link>
                </div>
            </CardBody>
            </Card>
        </div>
    );
  }
}
